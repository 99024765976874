.footer
    background-color: #603813
    padding-top: 10vh !important
    padding-bottom: 10vh !important
    padding-left: 12.5vw !important

    @media screen and (max-width: 992px)
        padding-right: 12.5vw !important

    .left-side
        display: flex
        flex-direction: column
        justify-content: space-around

        .large-text
            font-weight: 600
            font-size: 2.5rem
            margin-bottom: 0
            color: #ffffff

        .wrapper
            display: inline-flex
            width: 100%

        .text
            font-weight: 600
            font-size: 1.5rem
            line-height: 1.2em
            color: #ffffff

            p
                margin: 0
                text-overflow: ellipsis !important
                overflow: hidden !important
                white-space: nowrap !important

            .icon
                color: #ffffff
                width: 1.2em
                height: 1.2em
                align-self: stretch
        
        .social-media
            display: flex
            justify-content: space-between

    .image
        padding: 5em !important

        @media screen and (max-width: 992px)
            padding-left: 0 !important
            padding-right: 0 !important
            padding-bottom: 0 !important
