.team
    padding: 0 0 0 0 !important
    width: 100% !important

    .label
        background-color: #FEDA95 !important
        display: flex
        align-items: center
        justify-content: center
        padding-top: 5vh !important
        padding-bottom: 5vh !important

        .large-text
            background-color: #f7941d !important
            width: 50%
            border: 10px solid #BC7229
            border-radius: 50px
            font-weight: 700
            font-size: 2rem
            line-height: 1.5rem
            padding: 15px
            color: #FFFFFF !important

        .text
            font-weight: 800
            font-size: 2.5rem
            line-height: 2.5rem
            padding-left: 1rem
            padding-right: 1rem
            padding-bottom: 0
            @media screen and (max-width: 768px)
                font-size: 2rem
                line-height: 2rem

        .gold
            color: #F8AC1A
        .silver
            color: #A2ADB6
        .bronze
            color: #BC7229
        
        .icon
            vertical-align: bottom
            height: 2.5rem
            width: 2.5rem
            align-self: stretch
            @media screen and (max-width: 768px)
                height: 2rem
                width: 2rem
        .crown
            @media screen and (max-width: 768px)
                height: 2rem
                width: 2rem

    .wrapper
        margin-top: 4vh
        margin-bottom: 4vh
        width: 100% !important
        align-items: center

        @media screen and (max-width: 768px)
            width: 90%
        
        .row
            margin-right: 0 !important
            margin-left: 0 !important
        
        .teamOrg
            display: flex
            width: 300px
            .circle-photo
                height: 20vh
                width: 20vh
                border-radius: 50%
                margin: 0 auto
                overflow: hidden
                display: flex
                justify-content: center
                align-items:center
                position: relative
                margin-bottom: 2vh

            .photo
                width: 100%
                height: 100%
                object-fit: cover
                object-position: center
                margin-bottom: 2vh
                transition: opacity 0.3s ease-in-out
                

.text-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative

    h3
        color: #F6921E
        font-size: 1.5rem
        word-wrap: break-word

    p
        font-weight: 500
        font-size: 1.25rem
        padding-top: 2px
