.coming-soon
    font-family: "Raleway"
    padding: 10vh 0
    .border
        border: 10px solid #F89521 !important
        border-radius: 10px
        width: fit-content
        margin: auto
        overflow: hidden

        .large-text
            font-weight: 700
            font-size: 3.5rem
            margin-bottom: 0

            @media screen and (max-width: 768px)
                font-size: 2rem

        .rotate180
            transform: rotate(180deg)
            float: right

        .icon
            width: 30%
            #large-gear
                animation: large-trans 6000ms linear infinite normal forwards

            @keyframes large-trans
                0%
                    transform: translate(5.057493px, 5.11567px) rotate(0deg)
                100%
                    transform: translate(5.057493px, 5.11567px) rotate(180deg)

            #small-gear
                animation: small-trans 6000ms linear infinite normal forwards

            @keyframes small-trans
                0%
                    transform: translate(18.701448px, 18.552789px) rotate(180deg)
                100%
                    transform: translate(18.701448px, 18.552789px) rotate(0deg)