.treasure
    font-family: "Raleway"
    background-color: #F89521
    max-height: 10vh
    min-height: 10vh
    padding: 0 !important   

    border-bottom: 2vh solid #603813

    margin-bottom: 12vh

    .navbar-brand
        margin: 0

    .toggle
        position: absolute
        right: 50px

    .navbar-collapse
        max-height: 90vh
        overflow-y: auto
        @media (max-width: 992px)
            background-color: #F89521
            margin-top: 10vh
            padding-top: 10vh

            a
                text-align: center

    .logo
        max-height: 20vh
        background-color: #EDF1F4

        margin-left: 50px
        margin-right: 5vw
        margin-top: 10vh

        border: 2vh solid #603813
        border-top: 0
        border-radius: 0 0 50% 50%
        border-bottom-right-radius: 50%
        border-bottom-left-radius: 50%

        padding-left: 1vw
        padding-right: 1vw

        @media (max-width: 992px)
            position: absolute
            left: 50px
            top: 0
            margin-top: 0
            margin-left: 0

    .btn
        padding-left: 5px
        padding-right: 5px
        font-weight: 600 !important
        border-radius: 15px
        font-size: 1.5rem

        @media screen and (max-width: 768px)
            margin-bottom: 5px !important
            font-size: 1.2rem

    .btn:hover,
    .btn:focus
        color: white
        background-color: #F05540

    .btn-link
        text-decoration: none
        font-weight: 600 !important
        color: #324A5E
