.faq
    background-color: #F4F1ED

.wrapper-faq
    background-color: #FEDA95
    width: 75% !important
    @media screen and (max-width: 768px)
        width: 90% !important

    padding: 50px !important
    border-radius: 50px

    margin-top: 5vh !important
    margin-bottom: 5vh !important

    .large-text
        font-weight: 600
        font-size: 4rem
        margin-bottom: 0
    .text
        font-weight: 600
        font-size: 1.2rem

    ul
        background-color: #FEDA95 !important
    li
        background-color: #FEDA95 !important
        border-bottom: 2px solid black
        padding-top: 10px

        p
            margin-bottom: 0
        
        .trailing-icon
            float: right