.any
    font-family: "Raleway"

    .large-text
        font-weight: 700
        font-size: 3.5rem
        line-height: 3.5rem
        margin-bottom: 1em
        @media screen and (max-width: 768px)
            font-size: 2rem
    .med-text
        font-weight: 700
        font-size: 2rem
        line-height: 2rem
        margin: 1em 0
        @media screen and (max-width: 768px)
            font-size: 1.2rem
    .text
        font-weight: 600
        font-size: 1.2rem
        @media screen and (max-width: 768px)
            font-size: 1rem
    
    .padright
        padding-right: 7vw
        text-align: justify
        @media screen and (max-width: 768px)
            text-align: left

.rules
    .textred
        color: red !important
    .textthick
        font-weight: 700
    
    .left-side
        padding-left: 12vw
        align-items: center !important

        @media screen and (max-width: 768px)
            padding-right: 10vw !important
            padding-left: 10vw !important
    
    .bg-gray
        background-color: #F4F1ED
    
    .sep
        padding: 2em 0

.privacy
    .any
        padding-left: 12vw
        padding-bottom: 10vh
        @media screen and (max-width: 768px)
            padding-left: 10vw
            padding-right: 10vw
    
    a
        color: #BC7229
        font-weight: 600
        font-size: 1.2rem

.terog
    width: 50%
    padding: 3rem
    @media screen and (max-width: 768px)
        width: 100% !important
        padding: 0
