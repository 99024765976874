.teamsetup
    font-family: "Raleway"
    margin-bottom: 10vh

    .large-text
        font-weight: 700
        font-size: 3.5rem
        margin-bottom: 0
    .text
        font-weight: 600
        font-size: 1.2rem

    .left-side
        padding-left: 12vw
        align-items: center !important

        @media screen and (max-width: 768px)
            padding-right: 12vw !important
        
        .my-form
            @media screen and (max-width: 768px)
                margin: 0 !important
                padding-bottom: 10vw

            .form-control
                border: 0 !important
                border-bottom: 3px solid black !important
                font-size: 1.1rem
                font-weight: 600
                background-color: transparent
                border-radius: 0
                margin-bottom: 3vh

                &::-webkit-input-placeholder
                    color: #000000
                    font-size: 1.1rem
            
            .form-control:focus
                box-shadow: none
                -moz-box-shadow: none
                -webkit-box-shadow: none


            .form-icon
                border: 0px !important
                border-bottom: 3px solid black !important
                margin-left: 5%
                height: 3em
                margin-bottom: 3vh
                color: #000000 !important
            
            .form-check-label
                font-size: 1.2rem
                font-weight: 600
                color: #000000 !important
            
            p
                text-align: center
                font-size: 1.2rem
                line-height: 1.2rem
                font-weight: 800
                color: red
        
        .tabs-container
            border-color: #f7941d !important
            .nav-link
                color: #000000
                font-weight: 700
                font-size: 1.5rem
                line-height: 1.5rem
            .nav-link:hover
                border-color: #f7941d !important

            .active
                border-color: #f7941d #f7941d white #f7941d !important
                color: #f7941d !important
                background-color: transparent !important
            .active:hover
                border-color: #f7941d #f7941d white #f7941d !important

        .text-center 
            button
                background-color: #f7941d !important
                width: 100%
                border: 0
                font-weight: 700
                font-size: 1.5rem
                line-height: 1.5rem
                padding: 15px
                color: #603813 !important

                .trailing-icon
                    float: right
