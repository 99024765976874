.profile
    font-family: 'Raleway'

    .giant-text
        font-weight: 800
        font-size: 3rem
        line-height: 3rem
    .warning
        color: red

    .large-text
        font-weight: 700
        font-size: 1.5rem
        line-height: 1.5rem
        
    .text
        font-weight: 600
        font-size: 1.3rem
        line-height: 1.3rem

    .row
        height: 78vh !important
    
    .displaynone
        display: none

    .right-side
        margin-top: -12vh
        padding-top: 15vh
        height: 90vh !important
        padding-left: 5vw
        padding-right: 15vw
        overflow: scroll
        @media screen and (max-width: 768px)
            padding-right: 5vw

        .underline
            border-top: 3px solid #000000
            width: 50vw
            margin-bottom: 4vh !important
            @media screen and (max-width: 768px)
                margin-bottom: 2vh !important
                width: 90vw

        .captain
            display: flex
        
        .crown
            padding-right: 10px

        .warning
            color: #F05540 !important

    .label
        display: flex
        align-items: center
        text-align: center
        cursor: pointer
        padding-top: 0.5rem
        padding-left: 0.5rem
        padding-right: 0.5rem

        .icon
            width: 1.3rem
            height: 1.3rem
            align-self: center
    .label:hover
        color: #603813 !important
        border-bottom: 1px solid #603813
    
    .highlight:hover>p
        color: #603813 !important

    .sidebar
        background: #F5F5F5
        margin-top: -12vh
        padding-top: 15vh
        padding-bottom: 5vh
        display: flex
        flex-direction: column
        justify-content: space-between !important

        @media screen and (max-width: 768px)
            padding-bottom: 10vh
            padding-top: 12vh
            margin-bottom: 5vh

        .squid
            margin-bottom: 5vh

        .sidebar-text
            font-weight: 600
            font-size: 1.3rem
            line-height: 1.3rem
            color: #000000
            @media screen and (max-width: 768px)
                font-size: 1.5rem
                line-height: 1.5rem

        .selected
            font-weight: 600
            font-size: 1rem
            line-height: 1rem
            padding-left: 2rem
            // color: #603813
            @media screen and (max-width: 768px)
                font-size: 1.5rem
                line-height: 1.5rem

    button
        align-self: center
        margin-top: 5vh
        background-color: #f7941d !important
        border: 0 !important
        font-weight: 700
        font-size: 1.5rem
        line-height: 1.5rem
        padding: 15px
        color: #603813 !important
        @media screen and (max-width: 768px)
            width: 100% !important

        .trailing-icon
            float: right

.modal
    font-family: 'Raleway'
    .large-text
        font-weight: 700
        font-size: 1.5rem
        line-height: 1.5rem
    .delete
        align-self: center
        margin-top: 5vh
        background-color: #f7941d !important
        border: 0 !important
        font-weight: 700
        font-size: 1.5rem
        line-height: 1.5rem
        padding: 15px
        color: #603813 !important
        width: 100% !important

        .trailing-icon
            float: right