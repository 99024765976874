.team-issues
    font-family: "Raleway"
    padding-left: 20vw !important
    padding-top: 2vh
    padding-bottom: 2vh
    margin-top: -12vh !important
    margin-bottom: 2vh
    align-items: center !important
    border-bottom: 2px solid #603813
    background-color: #F4F1ED

    @media screen and (max-width: 768px)
        padding-right: 12vw !important
        padding-left: 12vw !important
        margin-top: -12vh !important
        padding-top: 12vh !important
    
    .large-text
        font-weight: 700
        font-size: 3.5rem
        margin-bottom: 0
    .text
        font-weight: 600
        font-size: 1.2rem
    .warning
        color: red
    p
        padding-left: 0 !important

    .right-side
        button
            background-color: #f7941d !important
            width: 50%
            border: 0
            font-weight: 700
            font-size: 1.5rem
            line-height: 1.5rem
            padding: 15px
            color: #603813 !important
            @media screen and (max-width: 768px)
                width: 100%

            .trailing-icon
                float: right
            a
                color: #603813 !important
                text-decoration: none