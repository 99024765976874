.forgot-pass
    font-family: "Raleway"
    .large-text
        font-weight: 700
        font-size: 3.5rem
        margin-bottom: 0
    .text
        font-weight: 600
        font-size: 1.2rem
    .left-side
        padding-left: 12vw
        align-items: center !important

        @media screen and (max-width: 768px)
            padding-right: 12vw !important

        .inline
            display: inline
            font-size: 1.2rem
            line-height: 2rem
        a
            display: inline
            color: black !important
            text-decoration: underline
            font-size: 1.2rem
            line-height: 2rem 
.error 
    text-align: center
    font-size: 1.2rem
    line-height: 1.2rem
    font-weight: 800
    color: red

.message 
    text-align: center
    font-size: 1.2rem
    line-height: 1.2rem
    font-weight: 800
    color: green
