.home
    font-family: "Raleway"
    margin-bottom: 5vh

    .large-text
        font-weight: 700
        font-size: 3.5rem
        line-height: 3.5rem
        margin-bottom: 1.5rem
        @media screen and (max-width: 768px)
            font-size: 2rem
            line-height: 2rem
    .text
        font-weight: 600
        font-size: 1.2rem

    .col-md-6,
    .col-sm-12,
    .col-xs-12
        display: flex
        flex-direction: column
        justify-content: space-between !important

        @media screen and (max-width: 768px)
            margin-bottom: 4em
            padding-top: 2vh

    .social-media
        display: flex
        text-align: center
        justify-content: space-between
        .social-icon
            @media screen and (max-width: 400px)
                height: 24px !important
                width: 24px !important

    .right-side
        padding-right: 12vw

        @media screen and (max-width: 768px)
            padding-left: 12vw !important

        a
            color: #BC7229
            font-weight: 600
            font-size: 1.2rem

        .buttons
            min-width: 40%

            button
                width: 100%
                padding: 15px
                margin-top: 2vh
                display: block
                border-width: 5px
                text-align: left !important

                .trailing-icon
                    float: right
                    color: #603813 !important

                a
                    text-decoration: none
                    color: #603813 !important
                    font-weight: 700
                    font-size: 1.3rem
                    line-height: 1.3rem

                    @media screen and (max-width: 768px)
                        font-size: 1.2rem
                        line-height: 1.2rem
                        padding-right: 0.5rem
                    
        .button-outline
            background-color: transparent !important
            border-radius: 10px
            border-color: #f7941d

        .button-fill
            background-color: #f7941d !important
            border-color: transparent
