.contact-background
    background-color: #F4F1ED
    padding-bottom: 10vh !important

.contact
    width: 75% !important
    padding: 10vh 0
    @media screen and (max-width: 768px)
        padding-top: 10vh !important
        padding-left: 0 !important
        padding-right: 0 !important

    .large-text
        font-weight: 600
        font-size: 4rem
        margin-bottom: 0
        @media screen and (max-width: 768px)
            font-size: 3rem
    .text
        font-weight: 600
        font-size: 1.2rem
    
    .red
        color: red
    .green
        color: green

    .my-form
        padding: 0 5vw
        @media screen and (max-width: 768px)
            margin: 0 !important
            padding-bottom: 10vw
            padding: 0
        
        .wrapper
            display: flex
            @media screen and (max-width: 768px)
                display: block
            .form-control
                flex: 0 0 100%

        .input-group
            padding: 0 2vw
            .form-control
                border: 0 !important
                border-bottom: 3px solid black !important
                font-size: 1.1rem
                font-weight: 600
                background-color: transparent
                border-radius: 0
                margin: 3vh 0

                &::-webkit-input-placeholder
                    color: #000000
                    font-size: 1.1rem
                
            .form-control:focus
                box-shadow: none
                -moz-box-shadow: none
                -webkit-box-shadow: none

            .message
                border: 3px solid black !important

        button
            background-color: #f7941d !important
            width: 30%
            border: 0
            font-weight: 700
            font-size: 1.5rem
            line-height: 1.5rem
            padding: 15px
            color: #603813 !important
            @media screen and (max-width: 768px)
                font-size: 1.2rem
                line-height: 1.2rem
