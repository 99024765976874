.sponsors
    background-color: #FEDA95
    padding: 5vh 0 !important

    .label
        display: flex
        align-items: center
        justify-content: center
        padding-top: 5vh !important
        padding-bottom: 5vh !important

        .large-text
            font-weight: 800
            font-size: 3.5rem
            line-height: 3.5rem
            padding-left: 1rem
            padding-right: 1rem

            background: linear-gradient(90deg, #F05A28 0%, #F3B46A 52.25%, #603813 106.44%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent
            @media screen and (max-width: 768px)
                font-size: 2rem
                line-height: 2rem

        .text
            font-weight: 800
            font-size: 2.5rem
            line-height: 2.5rem
            padding-left: 1rem
            padding-right: 1rem
            padding-bottom: 0
            @media screen and (max-width: 768px)
                font-size: 2rem
                line-height: 2rem

        .gold
            color: #F8AC1A
        .silver
            color: #A2ADB6
        .bronze
            color: #BC7229
        .custom 
            color: #F05A28
        
        .icon
            vertical-align: bottom
            height: 2.5rem
            width: 2.5rem
            align-self: stretch
            @media screen and (max-width: 768px)
                height: 2rem
                width: 2rem
        .crown
            @media screen and (max-width: 768px)
                height: 2rem
                width: 2rem

    .wrapper
        background-color: #F4F1ED
        border-radius: 50px
        width: 75%
        @media screen and (max-width: 768px)
            width: 90%
        
        .row
            margin-right: 0 !important
            margin-left: 0 !important
            align-items: center

        .col-lg-4,
        .col-md-6,
        .col-sm-12
            display: flex
            .photo
                max-width: 17vw
                max-height: 17vh
                align-self: center
                border-radius: 10px
                cursor: pointer
                @media screen and (max-width: 768px)
                    max-width: 40vw
                    max-height: 40vh
                
